










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsCodeSelect extends Vue {
  codeId:number=1;
  changeCurrency (val) {
    this.$Api.member.setCurrency(val).then((result) => {
      this.$message({
        message: this.$t('changeCurSuccess') as string,
        type: 'success'
      });
      this.$store.dispatch('setCurrency', result.ReturnValue);
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  LoadData () {
    this.$Api.shoppingCart.LoadData().then((result) => {
      console.log(result, 'shoppingCart');
      this.codeId = result.Currency.Id;
    });
  }

  // get codeId () {
  //   if (this.$Storage.get('currency')) {
  //     return this.$Storage.get('currency').Id;
  //   } else {
  //     return 1;
  //   }
  // }

  // set codeId (val) {
  //   this.changeCurrency(val);
  // }

  mounted () {
    this.LoadData();
  }
}
